const defaultScope =
  "openid profile email stops routes pronunciations settings provision audio devices notes audit reports"

const vehicleScope = "vehicles vehicles:edit vehicles:command vehicles:diagnostic vehicles:notes"

const operatorScope = "operators operator:Kinetic operator:TranzUrban operator:Mana operator:Uzabus"

const allScopes = `${defaultScope} ${vehicleScope} ${operatorScope}`

export default {
  mapbox: {
    zoom: 13.5,
    zoomForInfoPanel: 15.5,
    center: [174.775, -41.293],
    style: "mapbox://styles/mapbox/streets-v11",
  },
  routeTypeMapping: [
    { type: "bus", ids: [3] },
    { type: "train", ids: [0, 1, 2] },
    { type: "school", ids: [6, 712] },
    { type: "cable-car", ids: [5] },
    { type: "ferry", ids: [4] },
    { type: "other", ids: [4] },
  ],
  authConfig: {
    scope: allScopes,
  },
}
