const state = {
  loading: true,
  user: null,
  accessToken: null,
  gtfs: {
    stops: [],
    routes: [],
    vehicles: [],
    devices: [],
    notes: [],
    trip_variants: [],
    trip_stops: [],
    audit_logs: [],
  },
  gtfs_rt: {
    vehicle_positions: [],
  },
  selected_stop: {},
  selected_route: {},
  selected_vehicle: {},
  settings: {},
  pronunciations: [],
  lexicons: [],
  snackbar: {},
  selected_device: {},
  previousNavigationRoute: null,
  selected_note: {},
}

export default state
