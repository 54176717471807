import "@/plugins/vue-composition-api"
import "@/styles/styles.scss"
import "@/styles/next-stop/main.scss"
import axios from "axios"
import Vue from "vue"
import VueMeta from "vue-meta"
import App from "./App.vue"
import config from "./config"
import axiosIns from "./plugins/axios"
import vuetify from "./plugins/vuetify"
import router from "./router"
import store from "./store"
import { Auth0Plugin } from "./auth"

Vue.config.productionTip = false

store.$apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})

store.$gtfsrtClient = axios.create({
  baseURL: `${process.env.VUE_APP_OPEN_DATA_URL}gtfs-rt/`,
  headers: {
    "x-api-key": process.env.VUE_APP_OPEN_DATA_API_KEY,
  },
})

Vue.prototype.$config = config
Vue.prototype.$config.mapbox.accessToken = process.env.VUE_APP_MAPBOX_TOKEN

// Install the authentication plugin
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH_DOMAIN,
  clientId: process.env.VUE_APP_AUTH_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH_AUDIENCE,
  scope: config.authConfig.scope,
  onRedirectCallback: appState => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
  },
})

Vue.use(VueMeta)

new Vue({
  router,
  store,
  vuetify,
  axios,
  axiosIns,
  render: h => h(App),
}).$mount("#app")
