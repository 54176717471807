import globalHelper from "@/mixins/globalHelpers"

// eslint-disable-next-line import/prefer-default-export
export const actions = {
  init({ dispatch, commit }) {
    Promise.all([
      dispatch("getRoutes"),
      dispatch("getStops"),
      dispatch("getVehicles"),
      dispatch("getDevices"),
      dispatch("getNotes"),
      dispatch("getNextStopSettings"),
    ]).then(() => commit("END_LOADING"))
  },
  getStops({ commit }) {
    commit("START_LOADING")

    return new Promise((resolve, reject) => {
      this.$apiClient
        .get("next-stop/stops")
        .then(response => {
          commit("SET_STOPS", response.data)
        })
        .then(() => {
          commit("END_LOADING")
          resolve()
        })
        .catch(error => {
          commit("END_LOADING")
          reject(error)
        })
    })
  },
  getStop({ commit }, stopId) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .get(`next-stop/stop/${stopId}`)
        .then(response => {
          commit("SET_SELECTED_STOP", response.data)
        })
        .then(() => resolve())
        .catch(error => {
          reject(error)
        })
    })
  },
  saveStopConfig({ commit, state }, settings) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .post(`next-stop/stop/${state.selected_stop.stop_id}/config`, settings)
        .then(response => {
          commit("SET_STOP_CONFIG", { ...state.selected_stop, config: response.data })
          commit("SET_SELECTED_STOP", { ...state.selected_stop, config: response.data })
          commit("SET_SNACKBAR", { text: "The new configuration has been saved", color: "success" })
        })
        .then(() => resolve())
        .catch(error => {
          commit("SET_SNACKBAR", { text: "The new configuration could not be saved", color: "error" })
          reject(error)
        })
    })
  },
  getVehicles({ commit }) {
    return new Promise(resolve => {
      this.$apiClient
        .get("next-stop/vehicles")
        .then(response => {
          commit("SET_VEHICLES", response.data)
        })
        .then(() => {
          commit("END_LOADING")
          resolve()
        })
    })
  },
  getGtfsrtVehiclePositions({ commit }) {
    return new Promise((resolve, reject) => {
      this.$gtfsrtClient
        .get("vehiclepositions")
        .then(response => {
          const vehiclePositions = response.data.entity ? response.data.entity.map(data => data.vehicle) : []
          commit("SET_GTFSRT_VEHICLE_POSITIONS", vehiclePositions)
          resolve(vehiclePositions)
        })
        .catch(error => {
          console.error(error)
          reject(error)
        })
    })
  },
  getAuditLogs({ commit }, { start_datetime, end_datetime, type, id, cancelToken }) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .get("audit-logs", {
          params: {
            start_datetime,
            end_datetime,
            type,
            id,
          },
          cancelToken,
        })
        .then(response => {
          commit("END_LOADING")
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getPositions({ commit }, { vehicle_id, start_datetime, end_datetime, cancelToken }) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .get("next-stop/positions", {
          params: {
            vehicle_id,
            start_datetime,
            end_datetime,
          },
          cancelToken,
        })
        .then(response => {
          commit("END_LOADING")
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getVehicle({ commit }, vehicleId) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .get(`next-stop/vehicle/${vehicleId}`)
        .then(response => {
          commit("SET_SELECTED_VEHICLE", response.data)
        })
        .then(() => resolve())
        .catch(error => {
          reject(error)
        })
    })
  },
  getVehicleStatus({ commit }, vehicleId) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .get(`next-stop/vehicle/${vehicleId}`)
        .then(response => {
          commit("SET_SELECTED_VEHICLE_STATUS", response.data)
        })
        .then(() => resolve())
        .catch(error => {
          reject(error)
        })
    })
  },
  getVehicleScreenshot(context, vehicleId) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .get(`next-stop/vehicle/${vehicleId}/screenshot`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  saveVehicleConfig({ commit, state }, settings) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .post(`next-stop/vehicle/${state.selected_vehicle.vehicle_id}/config`, settings)
        .then(response => {
          commit("SET_SELECTED_VEHICLE", { ...state.selected_vehicle, config: response.data })
          commit("SET_SNACKBAR", { text: "The new configuration has been saved", color: "success" })
        })
        .then(() => resolve())
        .catch(() => {
          commit("SET_SNACKBAR", { text: "The new configuration could not be saved", color: "error" })
          reject()
        })
    })
  },

  executeVehicleCommand({ commit }, params) {
    const { command, vehicleId, data } = params
    const apiEndpoint = `next-stop/vehicle/${vehicleId}/command?type=${command}`

    if (command === "test_audio") {
      // Check if the volume is between 0 and 100
      if (data.volume !== undefined && data.volume !== null && (data.volume < 0 || data.volume > 100)) {
        commit("SET_SNACKBAR", {
          text: "Invalid volume.  Volume must be between 0 and 100.",
          color: "error",
        })

        return Promise.reject()
      }
    }

    const payload = data || {}

    return new Promise((resolve, reject) => {
      this.$apiClient
        .post(apiEndpoint, payload)
        .then(() => {
          commit("SET_SNACKBAR", {
            text: `The command <b>${command}</b> is successfully executed with the vehicle's device`,
            color: "success",
          })
        })
        .then(() => resolve())
        .catch(() => {
          commit("SET_SNACKBAR", {
            text: `The command <b>${command}</b> couldn't be successfully executed`,
            color: "error",
          })
          reject()
        })
    })
  },
  getRoutes({ commit }) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .get("next-stop/routes")
        .then(response => {
          const routes = response.data
          globalHelper.methods.sortByMixedTypeField(routes, "route_short_name")

          commit("SET_ROUTES", routes)
        })
        .then(() => resolve())
        .catch(error => {
          reject(error)
        })
    })
  },
  getRoute({ commit }, routeId) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .get(`next-stop/route/${routeId}`)
        .then(response => {
          commit("SET_SELECTED_ROUTE", response.data)
        })
        .then(() => resolve())
        .catch(error => {
          reject(error)
        })
    })
  },
  saveRouteConfig({ commit, state }, settings) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .post(`next-stop/route/${state.selected_route.route_id}/config`, settings)
        .then(response => {
          commit("SET_SELECTED_ROUTE", { ...state.selected_route, config: response.data })
          commit("SET_SNACKBAR", { text: "The new configuration has been saved", color: "success" })
        })
        .then(() => resolve())
        .catch(() => {
          commit("SET_SNACKBAR", { text: "The new configuration could not be saved", color: "error" })
          reject()
        })
    })
  },
  getNextStopSettings({ commit }) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .get("next-stop/settings")
        .then(response => {
          commit("SET_NEXT_STOP_SETTINGS", response.data)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  saveNextStopSettings({ commit }, newSettings) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .post("next-stop/settings", newSettings)
        .then(response => {
          commit("SET_NEXT_STOP_SETTINGS", response.data)
        })
        .then(() => commit("SET_SNACKBAR", { text: "The new configuration has been saved", color: "success" }))
        .then(() => resolve())
        .catch(error => {
          commit("SET_SNACKBAR", { text: "The new configuration has not been saved", color: "error" })
          reject(error)
        })
    })
  },
  getPronunciations({ commit }) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .get("next-stop/pronunciations")
        .then(response => {
          commit("SET_PRONUCIATIONS", response.data)
        })
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  addPronunciation({ state }, item) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .post(`next-stop/pronunciation`, {
          name: item.name,
          value: item.value,
        })
        .then(response => {
          state.pronunciations.unshift(response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updatePronunciation(context, item) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .put(`next-stop/pronunciation/${item.pronunciation_id}`, {
          name: item.name,
          value: item.value,
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  removePronunciation({ state }, item) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .delete(`next-stop/pronunciation/${item.pronunciation_id}`)
        .then(() => {
          state.pronunciations = state.pronunciations.filter(
            pronunciation => pronunciation.pronunciation_id !== item.pronunciation_id,
          )
          resolve(true)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getLexicons({ commit }) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .get("next-stop/lexicons")
        .then(response => {
          commit("SET_LEXICONS", response.data)
        })
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  saveLexicon(context, item) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .put(`next-stop/lexicon/${item.name}`, {
          tag: "phoneme",
          value: item.value,
          maori: !!item.maori,
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  removeLexicon({ commit }, name) {
    return new Promise(resolve => {
      this.$apiClient
        .delete(`next-stop/lexicon/${name}`)
        .then(() => {
          commit("SET_SNACKBAR", { text: `The lexicon ${name} is removed`, color: "success" })
          resolve(true)
        })
        .catch(error => {
          commit("SET_SNACKBAR", { text: `The lexicon could not be removed: ${error.message}`, color: "error" })
          resolve(false)
        })
    })
  },
  TTSpreview({ commit }, tts) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .get(`next-stop/audio/preview`, {
          params: {
            msg: tts,
          },
          responseType: "arraybuffer",
        })
        .then(response => {
          commit("SET_SNACKBAR", {
            text: `Previewing "${tts}"`,
          })
          const context = new AudioContext()
          context.decodeAudioData(response.data, buffer => {
            const source = context.createBufferSource()
            source.buffer = buffer
            source.connect(context.destination)
            source.start(0)
          })
        })
        .then(() => resolve())
        .catch(error => {
          commit("SET_SNACKBAR", { text: "The TTS can't be generated", color: "error" })
          reject(error)
        })
    })
  },
  IPApreview({ commit }, ipa) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .get(`next-stop/audio/preview`, {
          params: {
            msg: `<ipa ph="${ipa}"></ipa>`,
          },
          responseType: "arraybuffer",
        })
        .then(response => {
          commit("SET_SNACKBAR", {
            text: `Previewing "${ipa}"`,
          })
          const context = new AudioContext()
          context.decodeAudioData(response.data, buffer => {
            const source = context.createBufferSource()
            source.buffer = buffer
            source.connect(context.destination)
            source.start(0)
          })
        })
        .then(() => resolve())
        .catch(error => {
          commit("SET_SNACKBAR", { text: "The TTS can't be generated", color: "error" })
          reject(error)
        })
    })
  },
  async fullStopPreview({ commit, state }, { stopId, routeId = null }) {
    const stopConfig = await this.$apiClient.get(`next-stop/stop/${stopId}/config`)

    /* eslint-disable */
    const transfers = stopConfig.data.transfers_enabled
      ? stopConfig.data.transfers.map(transfer => {
        const route = state.gtfs.routes.find(r => r.route_id === transfer.route_id)

        return route.route_short_name
      })
      : []
    /* eslint-enable */

    const params = {
      hasPOI: !!stopConfig.data.additional_tts,
      hasCustomTransfer: stopConfig.data.transfers_enabled && !!stopConfig.data.custom_transfer_tts,
      transfers: transfers.join(","),
      routeId,
    }

    // Check and use a valid additional message for the route
    let additionalMessage = ""
    if (routeId && stopConfig.data.custom_additional_message_for_route) {
      const customMessage = stopConfig.data.custom_additional_message_for_route.find(route =>
        route.route_ids.includes(routeId),
      )
      additionalMessage = customMessage?.custom_additional_message_tts || ""
    }

    if (routeId && additionalMessage) {
      params.routeId = routeId
    }

    return new Promise((resolve, reject) => {
      this.$apiClient
        .get(`next-stop/audio/simulate/${stopId}`, { params })
        .then(response => {
          const audioFiles = []
          const audioPromises = []

          // Add all audio files

          // Next stop introduction TTS (legacy) (from global settings)
          audioFiles.push(new Audio(response.data.next_stop_msg_url))
          // Custom stop name TTS (from stop config)
          audioFiles.push(new Audio(response.data.stop_tts_url))

          // Transfers introduction TTS (from global settings)
          if (response.data.transfer_msg_url) {
            audioFiles.push(new Audio(response.data.transfer_msg_url))
          }

          // Custom transfer announcement TTS (from stop config)
          if (response.data.custom_transfer_tts_url) {
            audioFiles.push(new Audio(response.data.custom_transfer_tts_url))
          } else if (response.data.transfer_tts_urls) {
            // Route transfers numbers (from stop config)
            response.data.transfer_tts_urls.forEach((url, i) => {
              // If it is the last transfer add "and"
              if (i === response.data.transfer_tts_urls.length - 1 && i > 0) {
                audioFiles.push(new Audio(response.data.and_msg_url))
              }
              audioFiles.push(new Audio(url))
            })
          }

          // Additional announcements introduction TTS (from global settings)
          if (response.data.poi_msg_url) {
            audioFiles.push(new Audio(response.data.poi_msg_url))
          }

          // Point of interest message TTS (from stop config)
          if (response.data.poi_tts_url) {
            audioFiles.push(new Audio(response.data.poi_tts_url))
          }

          // Additional message TTS (from stop config) - under Point of interest additional message for specific route(s)
          if (response.data.poi_additional_by_route_tts_url) {
            audioFiles.push(new Audio(response.data.poi_additional_by_route_tts_url))
          }

          // Preload all audio
          audioFiles.forEach(audio => {
            audioPromises.push(
              new Promise(res => {
                audio.addEventListener("canplaythrough", () => {
                  res()
                })
              }),
            )
          })

          // Recursive play each file sequentially
          const play = file => {
            if (file.length === 0) {
              resolve()

              return
            }

            const audio = file.shift()
            audio.play()
            audio.addEventListener("ended", () => {
              play(file)
            })
          }

          // Only play once all audio is loaded
          Promise.all(audioPromises).then(() => {
            commit("SET_SNACKBAR", {
              text: `Previewing stop "${stopId}"`,
            })
            play(audioFiles)
          })
        })
        .catch(error => {
          commit("SET_SNACKBAR", { text: "The stop can't be previewed", color: "error" })
          reject(error)
        })
    })
  },
  ProvisionDevice({ commit }, config) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .post(`device`, config)
        .then(() => {
          commit("SET_SNACKBAR", { text: "The device has been provisioned", color: "success" })
          resolve()
        })
        .catch(error => {
          commit("SET_SNACKBAR", { text: `The device could not be provisioned: ${error.message}`, color: "error" })
          reject(error)
        })
    })
  },
  DecommissionDevice({ commit }, deviceID) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .delete(`device/${deviceID}`)
        .then(() => {
          commit("SET_SNACKBAR", { text: "The device has been decommissioned", color: "success" })
          resolve()
        })
        .catch(error => {
          commit("SET_SNACKBAR", { text: `The device could not be decommissioned: ${error.message}`, color: "error" })
          reject(error)
        })
    })
  },
  RunDiagnostic({ commit }, params) {
    const { command, vehicleId } = params
    const apiEndpoint = `next-stop/vehicle/${vehicleId}/diagnostic?type=${command}`

    return new Promise((resolve, reject) => {
      this.$apiClient
        .get(apiEndpoint)
        .then(result => {
          commit("SET_SNACKBAR", {
            text: `The command <b>${command}</b> was successfully executed with the vehicle's device`,
            color: "success",
          })
          resolve(result)
        })
        .catch(() => {
          commit("SET_SNACKBAR", {
            text: `The command <b>${command}</b> couldn't be successfully executed`,
            color: "error",
          })
          reject()
        })
    })
  },
  AddBaudRate({ commit }, config) {
    return new Promise(resolve => {
      this.$apiClient
        .post(`device/${config.deviceUUID}/baudRate/${config.baudRate}`)
        .then(() => {
          commit("SET_SNACKBAR", {
            text: `The baud rate ${config.baudRate} has been added to the device`,
            color: "success",
          })
          resolve(true)
        })
        .catch(error => {
          commit("SET_SNACKBAR", { text: `The device could not be provisioned: ${error.message}`, color: "error" })
          resolve(false)
        })
    })
  },
  RemoveBaudRate({ commit }, deviceID) {
    return new Promise(resolve => {
      this.$apiClient
        .delete(`device/${deviceID}/baudRate`)
        .then(() => {
          commit("SET_SNACKBAR", { text: "The baud rate has been removed from device", color: "success" })
          resolve(true)
        })
        .catch(error => {
          commit("SET_SNACKBAR", { text: `The device could not be decommissioned: ${error.message}`, color: "error" })
          resolve(false)
        })
    })
  },
  AddHardware({ commit }, config) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .post(`hardware`, config)
        .then(() => {
          commit("SET_SNACKBAR", { text: "The hardware IDs have been added", color: "success" })
          resolve()
        })
        .catch(error => {
          commit("SET_SNACKBAR", { text: `The hardware IDs could not be added: ${error.message}`, color: "error" })
          reject(error)
        })
    })
  },
  GetHardware(context, deviceID) {
    return new Promise(resolve => {
      this.$apiClient
        .get(`hardware/${deviceID}`)
        .then(() => {
          resolve(true)
        })
        .catch(() => {
          resolve(false)
        })
    })
  },
  IsProvisioned(context, deviceID) {
    return new Promise(resolve => {
      this.$apiClient
        .get(`device/${deviceID}`)
        .then(() => {
          resolve(true)
        })
        .catch(() => {
          resolve(false)
        })
    })
  },
  GetBaudRate(context, deviceID) {
    return new Promise(resolve => {
      this.$apiClient
        .get(`device/${deviceID}/baudRate`)
        .then(response => {
          resolve(response.data)
        })
        .catch(() => {
          resolve(null)
        })
    })
  },
  GetReleaseVersion(context, deviceID) {
    return new Promise(resolve => {
      this.$apiClient
        .get(`device/${deviceID}/releaseVersion`)
        .then(response => {
          resolve(`v${response.data.substring(0, 7)}`)
        })
        .catch(() => {
          resolve("Version not found")
        })
    })
  },
  getDevices({ commit }) {
    return new Promise(resolve => {
      this.$apiClient
        .get("next-stop/devices")
        .then(response => {
          commit("SET_DEVICES", response.data)
        })
        .then(() => {
          commit("END_LOADING")
          resolve()
        })
    })
  },
  getDevice({ commit }, deviceId) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .get(`next-stop/device/${deviceId}`)
        .then(response => {
          commit("SET_SELECTED_DEVICE", response.data)
        })
        .then(() => resolve())
        .catch(error => {
          reject(error)
        })
    })
  },
  getDeviceEventsByDeviceId({ commit }, { device_id, start_datetime, end_datetime, cancelToken }) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .get("next-stop/device-events", {
          params: {
            device_id,
            start_datetime,
            end_datetime,
          },
          cancelToken,
        })
        .then(response => {
          commit("END_LOADING")
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getDeviceEventsByType({ commit }, { event_type, start_datetime, end_datetime, cancelToken }) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .get("next-stop/device-events", {
          params: {
            event_type,
            start_datetime,
            end_datetime,
          },
          cancelToken,
        })
        .then(response => {
          commit("END_LOADING")
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getDeviceEventsByVehicleId({ commit }, { vehicle_id, event_type, start_datetime, end_datetime, cancelToken }) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .get("next-stop/device-events", {
          params: {
            vehicle_id,
            event_type,
            start_datetime,
            end_datetime,
          },
          cancelToken,
        })
        .then(response => {
          commit("END_LOADING")
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getDeviceEventByEventId({ commit }, params, cancelToken) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .get("next-stop/device-events", {
          params: { ...params },
          cancelToken,
        })
        .then(response => {
          commit("END_LOADING")
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  getNotes({ commit }, payload) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .get(`next-stop/notes`, {
          params: {
            type: payload?.type,
            foreign_id: payload?.foreign_id,
          },
        })
        .then(response => {
          commit("SET_NOTES", response.data)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getNote({ commit }, noteId) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .get(`next-stop/note/${noteId}`)
        .then(response => {
          commit("SET_SELECTED_NOTE", response.data)
        })
        .then(() => resolve())
        .catch(error => {
          reject(error)
        })
    })
  },
  addNote({ commit }, { formData }) {
    const payload = {
      title: formData.title,
      comments: formData.comments,
      type: formData.selectedType,
      foreign_id: formData.selectedForeignId.value,
      priority: formData.selectedPriority,
      status: formData.selectedStatus,
      claimant: formData.claimant,
      assigned: formData.assigned,
      location: formData.location,
    }

    return new Promise((resolve, reject) => {
      this.$apiClient
        .post(`next-stop/note`, payload)
        .then(response => {
          commit("ADD_NOTE", response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateNote({ commit, state }, settings) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .put(`next-stop/note/${state.selected_note.note_id}`, settings)
        .then(() => {
          commit("SET_SNACKBAR", { text: "The new configuration has been saved", color: "success" })
        })
        .then(() => resolve())
        .catch(() => {
          commit("SET_SNACKBAR", { text: "The new configuration could not be saved", color: "error" })
          reject()
        })
    })
  },
  removeNote({ commit }, noteId) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .delete(`next-stop/note/${noteId}`)
        .then(() => {
          commit("REMOVE_NOTE", noteId)
          resolve(true)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getOffRoutesReport({ commit }, { start_datetime, end_datetime, cancelToken }) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .get("next-stop/reports/off-routes", {
          params: {
            start_date: start_datetime,
            end_date: end_datetime,
          },
          cancelToken,
        })
        .then(response => {
          commit("END_LOADING")
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getTripAssignmentReport({ commit }, { start_datetime, end_datetime, cancelToken }) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .get("next-stop/reports/trips", {
          params: {
            start_date: start_datetime,
            end_date: end_datetime,
          },
          cancelToken,
        })
        .then(response => {
          commit("END_LOADING")
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getNetworkUsageReport({ commit }, { start_datetime, end_datetime, cancelToken }) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .get("next-stop/reports/network-usage", {
          params: {
            start_date: start_datetime,
            end_date: end_datetime,
          },
          cancelToken,
        })
        .then(response => {
          commit("END_LOADING")
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getNetworkUsageByDevice({ commit }, { start_datetime, end_datetime, device_id, cancelToken }) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .get("next-stop/reports/network-usage", {
          params: {
            start_date: start_datetime,
            end_date: end_datetime,
            device_id,
          },
          cancelToken,
        })
        .then(response => {
          commit("END_LOADING")
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getRouteTripsVariants({ commit }, { route_id }) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .get("next-stop/route-trips", {
          params: {
            route_id,
          },
        })
        .then(response => {
          commit("SET_TRIP_VARIANTS", response.data)
        })
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getStopsByTripVariant({ commit }, { trip_id }) {
    return new Promise((resolve, reject) => {
      this.$apiClient
        .get("next-stop/trip-stops", {
          params: {
            trip_id,
          },
        })
        .then(response => {
          commit("SET_TRIP_STOPS", response.data)
        })
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}
